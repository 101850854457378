.profile-component__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile-component__events-box {
    background-color: lightgray;
    width: 280px;
    margin: 5px 0 10px 0;
    border: black solid 1px;
    border-radius: 5px;
}
.profile-component__page-title {
    background-color: #4717f6;
    color: white;
    padding: 2px 0px;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
}
.profile-component__event-box {
    display: flex;
    flex-direction: column;
    margin: 5px 10px;
}
.profile-component__data-row-phone {
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
}
.profile-component__data-row {
    display: flex;
    justify-content: flex-end;
}
.profile-component__data-label {
    flex: 1;
    text-align: right;
    padding-right: 3px;
}
.profile-component__date-control {
    flex: 2;
}
.profile-component__state-row {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}
.profile-component__button-wrapper {
    flex-grow: 1;
    text-align: center;
    padding: 10px 0 5px 0;
}
.profile-component__update-button {
    background-color: yellow;
    padding: 3px 5px;
    border-radius: 5px;
}
// MEDIA ALTERATIONS ** MEDIA ALTERATIONS ** MEDIA ALTERATIONS **
//Iphone Series
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .profile-component__events-box {
        width: 320px;
    }
    .profile-component__data-row-phone {
        padding-right: 52px;
    }
    .profile-component__state-row {
        padding-right: 60px;
    }
}
//IPAD Series
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .profile-component__events-box {
        width: 400px;
    }
    .profile-component__data-row-phone {
        padding-right: 103px;
    }
    .profile-component__state-row {
        padding-right: 110px;
    }
}
//desktop
@media only screen and (min-width: 1024px) {
    .profile-component__events-box {
        width: 500px;
    }
    .profile-component__data-row-phone {
        padding-right: 173px;
    }
    .profile-component__state-row {
        padding-right: 180px;
    }
}
