.reg-user-list-component__box {
    text-align: center;
    // padding: 1rem;
    // margin: 0.5rem 0;
    padding-top: 10px;
    width: 80%;
    height: auto;
    // align-items: center;
    // background: #286DA8;
    color: lightgray;
    // border-left: black solid 1px;
    // border-right: black solid 1px;
    // border-bottom: black solid 1px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
}
.reg-user-list-component__link-wrapper {
    border: black solid 2px;
    border-radius: 5px;
    padding: 3px 0px;
    width: 100%;
    background: #286da8;
    color: white;
}
.reg-user-list-component__unreg-link-wrapper {
    border: black solid 2px;
    border-radius: 5px;
    padding: 3px 0px;
    width: 100%;
    background: orangered;
    color: black;
}
.reg-user-list-component__detail-link {
    color: white;
}
.reg-user-list-component__role-inicator {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 0.7em;
    font-weight: bold;
}
