.events-page__wrapper {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-left: 10vw;
}
.events-page__title-box {
    background-color: #4484ce;
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black double 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: 5px;
    padding: 2px 0px;
    color: white;
    text-align: center;
    font-weight: bold;
}
.events-page__events-box {
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black double 1px;
    border-radius: 5px;
    background-color: lightgray;
}
.events-page__no-events-image {
    max-width: 100%;
}
.events-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.events-marquee-compoment__events-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.events-marquee-component__graphic-image {
    max-width: 80vw;
}
/* greater than mobile */
@media only screen and (min-width: 415px) and (max-width: 1024px) {
    .events-wrapper {
        width: 300px;
        align-items: center;
    }
}
@media only screen and (min-width: 1025px) {
    .events-wrapper {
        width: 300px;
    }
}
