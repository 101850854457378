.admin-page__page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
}

// .admin-page__page-wrapper {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     background-color: white;
//     height: auto;
// }
// .admin-page__admin-wrapper{
//     background-color: yellow;
// }
// .admin-page__option-box{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }
// .admin-page__admin-option {
//     border: black solid 2px;
//     border-radius: 5px;
//     padding: 5px 10px;
//     background-color: royalblue;
//     color: white;
//     font-weight: bold;
//     font-size: 1.2rem;
//     text-align: center;
// }
// .admin-page__admin-option a:visited{
//     color:white;
// }
