.admin-basic-storage-page__page-wrapper {
    text-align: center;
    // padding: 1rem;
    // margin: 0.5rem 0;
    padding-top: 10px;
    width: 80%;
    height: auto;
    // align-items: center;
    background: #286DA8;
    color: white;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black solid 1px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
}