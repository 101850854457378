* {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
}
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
/* hide nav words when smaller than 700px */
@media (max-width: 500px) {
    .hide-sm {
      display: none;
    }
  }
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.App-events {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.landing {
    position: relative;
    background: url('./assets/background.jpg') no-repeat center center/cover;
    background-color: brown;
    height: 100vh;
}
.alert {
    padding: 0.8rem;
    margin: 1rem 0;
    opacity: 0.9;
    background: var(--light-color);
    color: #333;
}
.alert-primary {
    background-color: var(--primary-color);
    color: #fff;
}
.alert-light {
    background-color: var(--light-color);
    color: #333;
}
.alert-dark {
    background-color: var(--dark-color);
    color: #fff;
}
.alert-danger {
    background: red;
    color: #fff;
}
.alert-success {
    background: green;
    color: #fff;
}
.alert-warning {
    background: yellow;
    color: black;
}
