//====================================
.user-reg-overview__wrapper {
    // text-align: center;
    padding: 0;
    margin: 0;
    width: 280px;
    height: auto;
    background: #b1a296;
    color: white;
    border-radius: 5px;
    border: black solid 1px;
}
.user-reg-overview__section-header {
    color: white;
    text-align: center;
    font-weight: bold;
    padding: 5px 0px;
    background-color: #4484CE;
}
.user-reg-flex__event-wrapper {
    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    // border: lightslategray solid 1px;
}
.user-reg-flex__date {
    padding: 5px;
}
.user-reg-flex__location {
    flex-grow: 1;
    padding: 5px;
}
.user-reg-flex__cancel {
    padding: 5px;
}
// MEDIA ALTERATIONS ** MEDIA ALTERATIONS ** MEDIA ALTERATIONS **
//Iphone Series
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .user-reg-overview__wrapper {
        width: 320px;
    }
}
//IPAD Series
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .user-reg-overview__wrapper {
        width: 400px;
    }
}
//Iphone Series
@media only screen and (min-width: 1024px) {
    .user-reg-overview__wrapper {
        width: 500px;
    }
}

// //========================================
// .registrationtable {
//     text-align: left;
//     margin-left: 20px;
//     font-size: 0.8em;
//     width: 100%;
//     color: white;
// }
// .eventdate {
//     vertical-align: bottom;
// }
// .eventname {
//     padding-left: 10px;
//     color: white;
// }
// .eventlocation {
//     padding-left: 10px;
// }
// user-reg-overview__item {
//     padding: 10px 10px;
//     position: relative;
// }
// .user-reg-overview__date {
//     display: inline-block;
//     margin: 0 10px;
//     padding-top: 5px;
//     padding-bottom: 2px;
// }
// .user-reg-overview__name {
//     display: inline-block;
//     margin: 0 10px;
// }
// .user-reg-overview__city-state {
//     display: inline-block;
//     margin: 0 10px;
// }
// .user-reg-overview__cancel-button {
//     display: inline-block;

//     cursor: pointer;
// }
