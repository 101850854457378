.register-user-component__register-form {
    padding: 1rem;
    background-color: whitesmoke;
}
.register-user-component__signup-title {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    width: 100%;
    background-color: #4484ce;
    text-align: center;
    // font-size: 1.8rem;
    // color: #ff5454;
}
.register-user-component__register-form label {
    font-weight: bold;
}
.register-user-component__register-form label {
    display: block;
    margin-top: 0.5rem;
    width: 95%;
}
.register-user-component__register-form input {
    display: block;
    // margin-top: 0.5rem;
    width: 95%;
}
.register-user-component__register-form input[id*='terms'],
.register-user-component__register-form input[id*='terms'] + label {
    display: inline-block;
    width: auto;
    vertical-align: bottom;
}

.register-user-component__register-form input:not([type='checkbox']),
.register-user-component__register-form select {
    border: 1px solid #ccc;
    padding: 0.2rem 0.5rem;
    font: inherit;
}

.register-user-component__register-form input:focus,
.register-user-component__register-form select:focus {
    outline: none;
    background: #d8f3df;
    border-color: #2ddf5c;
}

.register-user-component__register-form input[type='checkbox'] {
    border: 1px solid #ccc;
    background: white;
    width: 1rem;
    height: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.register-user-component__register-form input[type='checkbox']:checked {
    background: #2ddf5c;
    border: #0e4f1f;
}

.register-user-component__register-form input.invalid,
.register-user-component__register-form select.invalid,
.register-user-component__register-form :invalid {
    border-color: red !important;
    background: #faacac;
}

.register-user-component__register-form button[type='submit'] {
    display: block;
    margin-top: 1rem;
}

@media (min-width: 40rem) {
    .register-user-component__register-form {
        margin: auto;
        width: 25rem;
    }
}

.register-user-component__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lightgray;
    border-left: black solid 1px;
    border-bottom: black solid 1px;
    border-right: black solid 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 80vw;
    padding-top: 10px;
}
.register-user-component__input-line {
    display: flex;
    flex-direction: row;
    width: 80vw;
    padding-bottom: 5px;
}
.register-user-component__input-label {
    flex-grow: 1;
    color: black;
    width: 30vw;
    text-align: right;
    padding-right: 5px;
    // padding:5px 3px;
}

.register-user-component__input-control {
    flex-grow: 1;
    width: 50vw;
    text-align: left;
    // padding:5px;
}
.register-user-component__adddress-input-control {
    flex-grow: 1;
    width: 50vw;
    text-align: left;
    // padding:5px;
}
.register-user-component__text-boxes {
    width: 100px;
}
.register-user-component__email-input-control {
    width: 250px;
}
.register-user-component__button-wrapper {
    padding-bottom: 5px;
    padding-top: 5px;
}
.register-user-component__register-button {
    background-color: yellow;
    padding: 5px 5px;
    margin: 5px 0;
    border-radius: 5px;
}
