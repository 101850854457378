.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.7);
}
.modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    /* height: 30vw; */
    z-index: 3;
    /* background-color: #fff; */
    /* padding: 10px; */
    /* border: black solid 1px; */
}
.modal__banner {
    background-color: #4484CE;
    color: white;
    text-align: center;
    padding: 3px 0px;
    border-bottom: black solid 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.modal__success__banner {
    background-color: #48b644;
    color: white;
    text-align: center;
    padding: 3px 0px;
    border-bottom: black solid 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.modal__warning__banner {
    background-color: 'yellow';
    color: #000;
    text-align: center;
    padding: 3px 0px;
    border-bottom: black solid 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.modal__danger__banner {
    background-color: #FA0505;
    color: white;
    text-align: center;
    padding: 3px 0px;
    border-bottom: black solid 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.close-button {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
}
.modal-content {
    margin-top: 40px;
}
