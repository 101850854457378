.date-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    aspect-ratio: auto;
    background-color: lightgray;

    &__month,
    &__day,
    &__year {
        flex: 1;
        text-align: center;
    }
}