.card {
    // width: ;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.card-warning {
    width: 100%;
    background-color: #ffcdd2;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.card-content {
    justify-content: center;
    text-align: center;
}
.formatted-paragraph {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
}
.root {
    display: flex;
    // justify-content: center;
    // width: 50%;
    // margin: 20, auto;
    // @media (max-width: 300 px){
    //     width: 300px;
    //     display: block;
    // }
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
    // width: '50%';
    // margin: '0 auto';
    // @media (max-width: 300px){
    //     width: 300;
    //     display: block;
    // }
}
:global {
yes-button {
        background-color: green;
        color: white;
        margin: 0 5px;

        &:hover {
            background-color: lightgreen;
        }
    }

    .no-button {
        background-color: lightblue;
        color: white;
        margin: 0 5px;

        &:hover {
            background-color: cyan;
        }
    }
}
