.registration-list-item__data-wrapper {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
    background-color: white;
    // align-items: center;
    // text-align: center;
}
.registration-list-item__registrar {
    color: black;
    text-align: left;
    padding-left: 15px;
    font-size: 1.1rem;
    // border: orange solid 1px;
}
.registration-list-item__numbers {
    color: black;
    text-align: right;
    padding-right: 15px;
    font-size: 1.2rem;
    flex-grow: 1;
}
// MEDIA ALTERATIONS ** MEDIA ALTERATIONS ** MEDIA ALTERATIONS **
//Iphone Series
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .registration-list-item__registrar {
        padding-left: 25px;
    }
    .registration-list-item__numbers {
        padding-right: 25px;
    }
}
//IPAD Series
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .registration-list-item__registrar {
        padding-left: 25px;
    }
    .registration-list-item__numbers {
        padding-right: 25px;
    }
}
//desktop
@media only screen and (min-width: 1024px) {
    .registration-list-item__registrar {
        padding-left: 35px;
    }
    .registration-list-item__numbers {
        padding-right: 35px;
    }
}
