.admin-component__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.admin-component__users-box {
    width: 280px;
}
.admin-component__options-box {
    width: 280px;
    margin-top: 5px;
    background: lightgray;
    border: black solid 1px;
    border-radius: 5px;
}
.admin-component__page-title {
    background-color: #4717f6;
    color: white;
    font-size: 1.3em;
    font-weight: bold;
    
    padding: 5px 0px;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
}
.admin-component__option-box {
    display: flex;
    flex-direction: column;
    margin: 5px 10px;
}
.admin-component__row-center {
    display: flex;
    justify-content: center;
}
.admin-component__data-row {
    display: flex;
    justify-content: flex-end;
}
.admin-component__data-label {
    flex: 1;
    text-align: right;
    padding-right: 3px;
}
.admin-component__data-control {
    flex: 2;
}
.admin-component__link-wrapper{
    border: black solid 2px;
    border-radius: 5px;
    margin: 5px 10px;
    padding: 5px 0px;
    width: 100%;
    text-align: center;
    background: #286DA8;
    color: white;
}
.admin-component__option-link{
    color: white;
}

// MEDIA ALTERATIONS ** MEDIA ALTERATIONS ** MEDIA ALTERATIONS **
//Iphone Series
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .admin-component__options-box {
        width: 320px;
    }
}
//IPAD Series
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .admin-component__options-box {
        width: 400px;
    }
}
//desktop
@media only screen and (min-width: 1024px) {
    .admin-component__options-box {
        width: 500px;
    }
}
